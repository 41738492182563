<template>
  <main>
    <b-row class="align-items-center justify-content-center h-full">
      <b-col cols="12" md="3" class="mx-auto text-center">
        <img class="d-block mx-auto mb-4" src="@/assets/images/locked.svg" width="65" height="65">
        <img class="mb-4" src="@/assets/images/login-logo.png" height="25">
        <h1 class="text-18 title mb-4">Welcome</h1>
        <b-form-input v-model="email" class="af-input-field mb-4" placeholder="Username or Email"></b-form-input>
        <b-form-input v-model="password" type="password" class="af-input-field mb-4" placeholder="Password"></b-form-input>
        <b-button @click="onSubmit" :disabled="processing" variant="primary" class="w-100">
          <span v-if="!processing">Login</span>
          <b-spinner v-else variant="white" small></b-spinner>
        </b-button>
      </b-col>
    </b-row>
    <p class="copy text-muted text-14 my-4">&copy; 2020 AirtimeFlip Technology LTD - RC 1621037</p>
  </main>
</template>

<script>
import { loginUser } from '@/apis/auth'

export default {
  data () {
    return {
      email: '',
      password: '',
      processing: false
    }
  },
  methods: {
    async onSubmit () {
      this.processing = true
      try {
        const { data } = await loginUser({
          email: this.email,
          password: this.password
        })
        if (data && data.success) {
          this.$store.commit('setToken', data.token)
          this.$store.commit('setUserData', data.data)
          this.$router.push('/')
        }
      } catch (error) {
        this.$bvToast.toast(error.response.data.error, {
          title: 'Notification',
          variant: 'danger',
          solid: true
        })
      } finally {
        this.processing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background-color: white;

  .h-full {
    min-height: 100vh;
  }

  .copy {
    position: absolute;
    bottom: 0;
    width: 100vw;
    text-align: center;
  }
}
</style>
